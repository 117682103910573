import React from "react";
import lmsImg from "../Assets/lms-img1.png";
const Lms = () => {
  return (
    <>
      <div className="bg-[#F6F7FD]">
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col items-start py-9">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Main Modules</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
          <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
            The proposed main modules of the LMS are as follows:
          </p>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-10 md:flex-row justify-between items-start py-9">
          <div className="w-full flex-1 items-start">
            <div className="flex flex-col gap-2 w-max">
              <h2 className="text-2xl font-bold">Teaching Module</h2>
              <div className="overflow-hidden">
                <div
                  className="flex"
                  style={{ animation: "scroll 10s linear infinite" }}
                >
                  <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                </div>
              </div>
            </div>
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              The consortium for the project is a diverse group comprising of
              three Universities and two stakeholders from end users, one each
              from public and private sectors. The lead role shall be assumed by
              KIU, where an energetic team of software scientist and
              educationists who teach at the department of Computer Science and
              Education, respectively is involved. National University of
              Science and Technology (NUST) has been opted as research partner,
              which is one of the leading research institutes. From private
              sectors Universities, City University of Science and IT Peshawar
              has been chosen for its obvious strength in the Computer Science
              and IT education, besides having a chain of Peshawar Model Schools
              and Colleges as part of University, where more than 35000 students
              are acquiring quality education.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex justify-center items-end h-full">
            <img src={lmsImg} alt="" />
          </div>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col items-start py-9">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Learning Module</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
          <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
            The student module will facilitate students to learn in a conducive
            environment. It will contain almost all the components which are
            listed in the teacher module to read and download the course
            content. The student must have the freedom to select specific
            courses, lectures, and send messages to teachers and peers as per
            his/her specific requirements. However, this module will be more
            customized based on requirements analysis.
          </p>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col items-start py-9">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Assessment Module</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
          <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
            The teacher will be able to generate assessment plans according to
            his/her school examination rules. After having an assessment plan,
            he/she will be able to assign specific marks to each component of
            the assessment. The assessment can be in different forms that
            include but are not limited to projects, live or offline quizzes,
            reading, presentations, open-book questions, mid-term examinations,
            and final term examinations.
          </p>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col items-start py-9">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Monitoring Module</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
          <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
            This proposed module will be helpful for administrators and teachers
            at school to monitor and improve their course contents. Each faculty
            member will be able to monitor his/her course regarding his school
            targets/deadlines by giving summaries of his course progress.
            Similarly, administrators will be able to monitor the progress of
            all the faculty members in their courses.
          </p>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col items-start py-9">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">AI Module</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
          <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
            The module will be using techniques from the area of machine and
            deep learning. At the moment three sub-modules are planned to be
            developed and research in these different areas will be carried out.
            Both PI and Dr. Wasi have extensive knowledge in deep and machine
            learning techniques. They will use modern techniques/algorithms to
            improve performance and to make LMS more customized for students and
            teachers
          </p>
          <h3 className="text-[#121010] text-base font-bold leading-[185.706%] my-2">
            “Some Features of AI”
          </h3>
          <ul className="pl-7" style={{ listStyleType:"inherit" }}>
            <li className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              This module will be able to give suggestions and future trends
              based on past activities of teachers and students using Artificial
              Intelligence technologies. For example, based on student LMS
              activity system should generate a warning or suggestion to the
              student for improvement his/her performance in a specific course.
            </li>
            <li className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              A video recommender system. This system shall be based on
              supervised learning algorithms are would recommend videos to
              students based on their aptitude and interest. The recommendations
              would be having inputs like streaming history, academic
              performance, aptitude, and so on.
            </li>
            <li className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              A document processing system based on natural language processing.
              This sub-module would be used to search documents based on
              students’ aptitude and discipline. The module would also use by
              students for summarization and other text mining benefits.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Lms;
