import React from "react";
import icon1 from "../Assets/icon1.svg";
import icon2 from "../Assets/icon2.svg";
import icon3 from "../Assets/icon3.svg";
import img1 from "../Assets/f-img1.jpeg";
import img2 from "../Assets/f-img2.jpg";
import img3 from "../Assets/f-img3.jpg";
const Footer = () => {
  return (
    <>
      <div className="bg-[#0f3045]">
        <div className=" w-[80%] mx-auto flex flex-col md:flex-row gap-10 justify-between py-[40px]">
          <ul>
            <h2 className="text-white text-[18px] font-normal leading-[107.563%] mb-5">
              Contact Details
            </h2>
            <li className="text-white text-base font-normal leading-[185.687%] flex align-middle gap-3 cursor-pointer">
              <img src={icon1} alt="" />
              kiu.edu.pk
            </li>
            <li className="text-white text-base font-normal leading-[185.687%] flex align-middle gap-3 my-2 cursor-pointer">
              <img src={icon2} alt="" />
              +92 0581196010 (Ext. 199)
            </li>
            <li className="text-white text-base font-normal leading-[185.687%] flex align-middle gap-3 cursor-pointer">
              <img src={icon3} alt="" />
              aftab.ahmed@kiu.edu.pk
            </li>
          </ul>
          <ul>
            <h2 className="text-white text-[18px] font-normal leading-[107.563%] mb-5">
              Project Pictures
            </h2>
            <div className="flex gap-2 flex-col sm:flex-row">
              <img className="w-full sm:w-[98px] h-auto sm:h-[98px]" src={img1} alt="" />
              <img className="w-full sm:w-[98px] h-auto sm:h-[98px]" src={img2} alt="" />
              <img className="w-full sm:w-[98px] h-auto sm:h-[98px]" src={img3} alt="" />
            </div>
          </ul>
          <ul>
            <h2 className="text-white text-[18px] font-normal leading-[107.563%] mb-5">
              Useful Links
            </h2>
            <li className="text-white text-base font-normal leading-[185.687%] flex align-middle">
              {" "}
              Local Challenge Fund{" "}
            </li>
            <li className="text-white text-base font-normal leading-[185.687%] flex align-middle mt-2">
              Directorate of Education Colleges Gilgit-Baltistan
            </li>
          </ul>
        </div>
        <div className="flex justify-center border-t-2 border-white mt-10 ">
          <p className="text-white py-5">All Rights Reserved to LCF Project</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
