import React from "react";

const Background = () => {
  return (
    <>
      <div className="bg-[#F6F7FD] w-full">
        <div className="w-[90%] md:[80%] mx-auto py-10">
          <div className="w-full md:flex-1 flex flex-col items-start justify-center gap-5">
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-bold">Background of the Project</h2>
              <div className="overflow-hidden">
                <div
                  className="flex"
                  style={{ animation: "scroll 10s linear infinite" }}
                >
                  <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                </div>
              </div>
            </div>
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              Education is a strong vehicle for the socio-economic
              transformation of societies. According to education statistics
              2016-17, about 35000 students were enrolled in secondary, higher
              secondary, and degree levels education in the public sector and
              private sectors of GB. This enrolment is increasing at an average
              of 15% per year. A good number of these students (42%) are females
              and in some districts like Hunza, Ghizer, and Astore, the female
              literacy is more than 70%. Affordability, access, and quality are
              the three major challenges for education in Gilgit Baltistan (GB).
              The absence of digital and online platforms for teaching,
              learning, and assessment of secondary and higher secondary
              education sector in GB, further worsens the quality and
              accessibility challenges, particularly during natural disasters.
            </p>
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              The challenge became more severe during the recent pandemic when
              none of the schools and colleges in GB could provide education to
              their students for more than eleven months. The digital divide
              thus deprived thousands of school children of access to education
              for many months. Besides the non-availability of digital and
              online platforms, the poor capacity of the teachers in the use of
              ICT for education is another major challenge. Karakoram
              International University (KIU), in the meanwhile, deployed state
              of the art Learning Management System (LMS) and digital platforms
              at its main campus Gilgit and sub-campuses at Hunza, Ghizer, and
              Chilas. About 5100 students were registered and educated at the
              portals. Spring and Fall 2020-21 semesters were completed with
              great success.
            </p>
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              They remained connected to education during the pandemic. Based on
              the successful experience of KIU and the apparent challenge of
              accessibility and quality of teaching in the secondary and higher
              secondary education at GB, this project will attempt to provide a
              customized Learning Management System (LMS) to the selected
              schools and Colleges of GB from public and private sectors,
              develop the capacity of teachers in these institutes for
              curriculum development, teaching, learning and assessment for
              digital and online systems. Hence the major objective of the
              project is to provide quality and accessible secondary and higher
              secondary education by providing online/digital platforms and
              developing the capacity of teacher and IT staff at these colleges
              and schools. Another major objective is to understand the
              challenges of digitalization in rural areas so recommendations
              could be made to other similar areas to address those challenges.
              In addition, lessons learned during this project will assist in
              the rapid digitalization of schools and colleges in
              Gilgit-Baltistan.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Background;
