import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

const App = () => {
  return (
    <>
      <Navbar />
      <Navigation />
      <Footer />
    </>
  );
};

export default App;
