import React from "react";
import img1 from "../Assets/a-img1.jpeg";
import img2 from "../Assets/a-img2.jpeg";
import img3 from "../Assets/a-img3.jpeg";
import img4 from "../Assets/a-img4.jpg";
import img5 from "../Assets/a-img5.jpg";
import img6 from "../Assets/a-img6.jpg";
import img7 from "../Assets/a-img7.jpg";
import img8 from "../Assets/a-img8.jpg";
import img9 from "../Assets/a-img9.JPG";
import img10 from "../Assets/a-img10.JPG";
import img11 from "../Assets/a-img11.JPG";
import img12 from "../Assets/a-img12.JPG";
import img13 from "../Assets/a-img13.JPG";
import img14 from "../Assets/a-img14.JPG";
import img15 from "../Assets/a-img15.JPG";
import img16 from "../Assets/a-img16.JPG";
import img17 from "../Assets/a-img17.JPG";
import img18 from "../Assets/a-img18.JPG";
import img19 from "../Assets/a-img19.JPG";
// import img20 from "../Assets/a-img20.JPG";
import img21 from "../Assets/a-img21.JPG";
import img22 from "../Assets/a-img22.JPG";
import img23 from "../Assets/a-img23.JPG";
import img24 from "../Assets/a-img24.JPG";
import img25 from "../Assets/a-img25.JPG";
import img26 from "../Assets/a-img26.JPG";
import img27 from "../Assets/a-img27.JPG";
import Video from "../Assets/seminar.mp4";

const Activity = () => {
  const Activity = [
    {
      img: img9,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img10,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img11,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img12,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img13,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img14,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img15,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img16,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img17,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img18,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img19,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img21,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img22,
      des: "Seminar on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img23,
      des: "Exhibition on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img24,
      des: "Exhibition on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img25,
      des: "Exhibition on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img26,
      des: "Exhibition on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img27,
      des: "Exhibition on 'Innovations in ICT Education and IT Exhibition'",
    },
    {
      img: img5,
      des: "Team LCF having a group photo with students of Kharamng",
    },
    {
      img: img6,
      des: "Dr Aftab giving presentation about LMS",
    },
    {
      img: img7,
      des: "Team LCF giving guiding how to use LMS to student",
    },
    {
      img: img8,
      des: "Team LCF giving guidance how to use LMS",
    },
    {
      img: img1,
      des: " ",
    },
    {
      img: img2,
      des: "A visit to DJ School Singal, Ghizer for data collection.",
    },
    {
      img: img3,
      des: "Dr. Aftab is interacting with the students of Al Amyn Model School Gulmit Gojal",
    },
    {
      img: img4,
      des: "LCF team visit to Al Amyn Model School Gulmit Gojal",
    },
  ];

  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="w-full mx-auto text-center flex justify-center">
          <div className="flex flex-col gap-2 w-max mx-auto">
            <h2 className="text-2xl font-bold text-wrap">
              A team of LCF project visit to schools for data collection
            </h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <video width="100%" muted autoPlay controls>
            <source src={Video} type="video/mp4" />
          </video>
          <h3 className="text-lg sm:text-2xl font-semibold text-Black text-center mt-4 group-hover:text-white/[.8]">
            One day seminar on Innovations in ICT Education and IT Exhibition
          </h3>
        </div>
        <div className="grid lg:grid-cols-2 gap-6 mt-9">
          {Activity.map((e) => (
            <a
              className="group relative block rounded-xl dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              href=".."
            >
              <div className="flex-shrink-0 relative rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                <img
                  className="w-full h-full absolute top-0 start-0 object-cover"
                  src={e.img}
                  alt="Description"
                />
              </div>

              <div className="absolute bottom-0 inset-x-0 z-10">
                <div className="flex flex-col h-full p-4 sm:p-6">
                  <h3 className="text-lg sm:text-lg font-semibold text-white group-hover:text-white/[.8]">
                    {e.des}
                  </h3>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Activity;
