import React from "react";
import Cimg1 from "../Assets/c-img1.png";
import Cimg2 from "../Assets/c-img2.png";
import Cimg3 from "../Assets/c-img3.png";
import Cimg4 from "../Assets/c-img4.png";
import Cimg5 from "../Assets/c-img5.png";
const Consortium = () => {
  return (
    <>
      <div className="bg-[#F6F7FD]">
        <div className="w-[90%] md:w-[80%] mx-auto md:flex-1 flex flex-col items-start justify-center gap-5 py-10">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Consortium of Project</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
          <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
            The consortium for the project is a diverse group comprising of
            three Universities and two stakeholders from end-users, one each
            from public and private sectors. The lead role shall be assumed by
            KIU, where an energetic team of computer scientists and
            educationists who teach at the Department of computer science and
            Education, respectively is involved. National University of Science
            and Technology (NUST) has opted as a research partner, which is one
            of the leading research institutes. From private sectors
            Universities, the City University of Science and IT Peshawar has
            been chosen for its obvious strength in the computer science and IT
            education, besides having a chain of Peshawar Model Schools and
            Colleges as part of the university, where more than 35000 students
            are acquiring quality education.
          </p>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-10 md:flex-row justify-between items-start py-9">
          <div className="w-full flex-1 items-start">
            <div className="flex flex-col gap-2 w-max">
              <h2 className="text-2xl font-bold">Role of Collaborators</h2>
              <div className="overflow-hidden">
                <div
                  className="flex"
                  style={{ animation: "scroll 10s linear infinite" }}
                >
                  <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                </div>
              </div>
            </div>
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              <b>Karakorum International University (KIU):</b> KIU will lead
              this project in coordination, management, development, and
              implementation of the project. Prof. Dr. Atta Ullah Shah will lead
              the requirement engineering part of the project whereas Dr. Aftab
              will lead the development work of LMS apart from the overall
              in-charge of the project. Mr. Khalid Amin will lead the training
              part of the project. Dr. Asif Khan will lead the overall
              educational curriculum aspects of the project.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex justify-center items-end">
            <img width={200} height={200} src={Cimg1} alt="" />
          </div>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-10 md:flex-row justify-between items-start py-9">
          <div className="w-full flex-1 flex items-center">
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              <b>
                National University of Science and Technology (NUST), Islamabad:
              </b>{" "}
              Two people from NUST are involved in this project. Dr. Wasi will
              deal with issues to implement AI modules and research in LMS
              whereas Dr. Azhar will assist in the overall implementation and
              planning of the project.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex justify-center items-end">
            <img width={150} height={100} src={Cimg2} alt="" />
          </div>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-10 md:flex-row justify-between items-start py-9">
          <div className="w-full flex-1 flex items-center">
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              <b>The City University of Science and IT (CUSIT), Peshawar:</b>{" "}
              CUSIT has strength in the Computer Science and IT Education,
              besides having a schools and colleges chain of Peshawar Model
              Schools and Colleges as part of University, where more than 35000
              students are acquiring quality education. The university focal
              persons of this project will assist in reviewing and assessing
              each step in this project by giving review feedback so the
              products of this project will be able to replicate to other areas
              of the country and world without any major hurdle.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex justify-center items-end">
            <img width={150} height={100} src={Cimg3} alt="" />
          </div>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-10 md:flex-row justify-between items-start py-9">
          <div className="w-full flex-1 flex items-center">
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              <b>Education Department, Government of Gilgit-Baltistan:</b> Being
              the custodian of government schools and colleges in GB, the
              department of education will provide a list of schools and
              colleges to be selected for this project study. The education
              department is expected to implement and get the maximum benefit
              from this project to improve the quality of education. The
              department is further expected that the lesson learned from this
              project will be replicated to all schools and colleges of
              Gilgit-Baltistan. As many schools and colleges have a shortage of
              specialized faculty members in remote areas of GB so this project
              can also be helpful to facilitate those schools. The department of
              Education will encourage those remote schools by providing
              hardware and internet facilities to implement products of this
              project.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex justify-center items-end">
            <img width={150} height={100} src={Cimg4} alt="" />
          </div>
        </div>
        <div className="w-[90%] md:w-[80%] mx-auto flex flex-col gap-10 md:flex-row justify-between items-start py-9">
          <div className="w-full flex-1 flex items-center">
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              <b>Aga Khan Education Service Pakistan (North) AKESP:</b> AKESP
              has more than 100 primary and higher secondary schools in
              Gilgit-Baltistan. One of the pioneer educational institutions of
              GB, the AKESP will also support this research work by implementing
              this project in its selected schools. AKESP will facilitate
              requirement analysis, execution of LMS, and training of its staff.
              Further, it is expected that AKESP will expand this project based
              on fulfilling its standards to other schools to facilitate
              students in the remote areas of GB.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex justify-center items-end">
            <img width={150} height={100} src={Cimg5} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Consortium;
