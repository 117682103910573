import React from "react";
// schools logos
import GcceLogo from "../Assets/gcce-logo.png";
import LearningLogo from "../Assets/learning-logo.png";
import QuaidLogo from "../Assets/quaid.png";
import AlamynLogo from "../Assets/alamyn.png";
import BeconLogo from "../Assets/becon.jpg";
import aoeLogo from "../Assets/aoe.png";
import DjLogo from "../Assets/djschool.png";
import GovtLogo from "../Assets/gbdoelogo.png";
const Schools = () => {
  return (
    <>
      <div className="container-2xl">
        <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div class="w-full mx-auto text-center flex justify-center mb-6">
            <div className="flex flex-col gap-2 w-max">
              <h2 className="text-2xl font-bold">
                Selected Schools and Collages in Public and Private Sectors of
                GB
              </h2>

              <div className="overflow-hidden">
                <div
                  className="flex"
                  style={{ animation: "scroll 10s linear infinite" }}
                >
                  <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              The consortium for the project is a diverse group comprising of
              three Universities and two stakeholders from end-users, one each
              from public and private sectors. The Lead role shall be assumed by
              KIU, where an energetic team of computer scientists and
              educationists who teach at the Department of computer science and
              Education, respectively is involved. National University of
              Science and Technology (NUST) has opted as a research partner,
              which is one of the leading research institutes. From private
              sectors Universities, the City University of Science and IT
              Peshawar has been chosen for its obvious strength in the computer
              science and IT education, besides having a chain of Peshawar Model
              Schools and Colleges as part of University, where more than 35000
              students are acquiring quality education.
            </p>
          </div>
          <div className="flex flex-col gap-4 my-6">
            <div className="w-2/3 sm:1/3 mx-auto text-center flex justify-center">
              <div className="flex flex-col gap-2 w-max">
                <h2 className="text-2xl font-bold">Private</h2>

                <div className="overflow-hidden">
                  <div
                    className="flex"
                    style={{ animation: "scroll 10s linear infinite" }}
                  >
                    <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-x-6 sm:gap-x-12 lg:gap-x-24 gap-10 sm:gap-0 my-5">
              <a href="https://gcce.pk/" target="_blank" rel="noreferrer">
                <img
                  className="w-[90%] mx-auto sm:mx-unset sm:w-[129px] h-auto object-contain"
                  src={GcceLogo}
                  alt=""
                />
              </a>
              <a href="https://tlag.pk" target="_blank" rel="noreferrer">
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={LearningLogo}
                  alt=""
                />
              </a>
              <a href="https://qhss.pk" target="_blank" rel="noreferrer">
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={QuaidLogo}
                  alt=""
                />
              </a>
              <a
                href="https://al-amynschool.edu.pk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={AlamynLogo}
                  alt=""
                />
              </a>
              <a href="https://nbh.net.pk/" target="_blank" rel="noreferrer">
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={BeconLogo}
                  alt=""
                />
              </a>
              <a href="https://aoe.edu.pk/" target="_blank" rel="noreferrer">
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={aoeLogo}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-4 my-6">
            <div class="w-2/3 sm:1/3 mx-auto text-center flex justify-center">
              <div className="flex flex-col gap-2 w-max">
                <h2 className="text-2xl font-bold">
                  Aga Khan Education Service, Pakistan
                </h2>

                <div className="overflow-hidden">
                  <div
                    className="flex"
                    style={{ animation: "scroll 10s linear infinite" }}
                  >
                    <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-x-6 sm:gap-x-12 lg:gap-x-24 gap-10 sm:gap-0 my-5">
              <a href="https://" target="_blank" rel="noreferrer">
                <img
                  className="w-[90%] mx-auto sm:mx-unset sm:w-[129px] h-auto object-contain"
                  src={DjLogo}
                  alt=""
                />
                <p>DJ School Sonikot Gilgit</p>
              </a>

              <a href="https://singul.lcfschools.pk/" target="_blank" rel="noreferrer">
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={DjLogo}
                  alt=""
                />
                <p>DJ Model High School Singal</p>
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-4 my-6">
            <div class="w-2/3 sm:1/3 mx-auto text-center flex justify-center">
              <div className="flex flex-col gap-2 w-max">
                <h2 className="text-2xl font-bold">
                  Government Education Department{" "}
                </h2>

                <div className="overflow-hidden">
                  <div
                    className="flex"
                    style={{ animation: "scroll 10s linear infinite" }}
                  >
                    <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-x-6 sm:gap-x-12 lg:gap-x-24 gap-10 sm:gap-0 my-5">
              <a
                href="https://ghs1.lcfschools.pk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[90%] mx-auto sm:mx-unset sm:w-[129px] h-auto object-contain"
                  src={GovtLogo}
                  alt=""
                />
                <p className="text-center">Govt Girls high School no1</p>
              </a>
              <a
                href="https://bhsm.lcfschools.pk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={GovtLogo}
                  alt=""
                />
                <p className="text-center">Govt Boy High school Minapin</p>
              </a>
              <a
                href="https://ghsg.lcfschools.pk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={GovtLogo}
                  alt=""
                />
                <p className="text-center">Govt Girls High School Gupis</p>
              </a>
              <a
                href="https://bhspi.lcfschools.pk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={GovtLogo}
                  alt=""
                />
                <p className="text-center">Govt Boys High school pakora</p>
              </a>
              <a
                href="https://bhst.lcfschools.pk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={GovtLogo}
                  alt=""
                />
                <p className="text-center">Govt Boys High School Tolti</p>
              </a>
              <a
                href="https://hstt.lcfschools.pk/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[90%] sm:w-[129px]  mx-auto sm:mx-unset h-auto object-contain"
                  src={GovtLogo}
                  alt=""
                />
                <p className="text-center">Govt High School Tololing</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schools;
