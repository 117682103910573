import React from "react";
import Logo from "../Assets/logo.png";
import img1 from "../Assets/twitter.svg";
import img2 from "../Assets/facebook.svg";
import img3 from "../Assets/youtube.svg";
import { Link } from "react-router-dom";
// import img4 from "../Assets/lms.svg";


const Navbar = () => {
  return (
    <>
      <div className="container-2xl">
        <div className="flex flex-col md:flex-row">
          <div className="w-full flex flex-1 py-2">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="w-full md:w-1/3 mx-0 md:mx-2">
            <div className="bg-[#1da1f2] py-2 h-1/2 flex items-center justify-center gap-5 md:rounded-t-none md:rounded-[25px]">
              <img className="cursor-pointer" src={img2} alt="" />
              <img className="cursor-pointer" src={img1} alt="" />
              <img className="cursor-pointer" src={img3} alt="" />
            </div>

            <div className="overflow-hidden py-2 ml-2">
              <div
                className="flex gap-8 w-full ml-3"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black">
                "We have implemented LMS in each School successfully"
                </p>
              </div>
            </div>
          </div>
        </div>
        <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-[#1da1f2] border-b border-gray-200 text-sm py-3 sm:py-0 dark:bg-gray-800 dark:border-gray-700">
          <nav
            className="relative max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex items-center justify-end">
              <div className="sm:hidden">
                <button
                  type="button"
                  className="hs-collapse-toggle w-9 h-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-white hover:bg-gray-800 hover:text-black-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                  data-hs-collapse="#navbar-collapse-with-animation"
                  aria-controls="navbar-collapse-with-animation"
                  aria-label="Toggle navigation"
                >
                  <svg
                    className="hs-collapse-open:hidden flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line x1="3" x2="21" y1="6" y2="6" />
                    <line x1="3" x2="21" y1="12" y2="12" />
                    <line x1="3" x2="21" y1="18" y2="18" />
                  </svg>
                  <svg
                    className="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              id="navbar-collapse-with-animation"
              className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block"
            >
              <div className="flex w-full flex-col justify-end gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
                <Link
                  className="font-[400] text-white text-[16.336px] leading-[104.065%] sm:py-6 dark:text-blue-500"
                  to="/"
                  aria-current="page"
                >
                  Home
                </Link>
                <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4">
                  <button
                    type="button"
                    className="flex items-center w-full text-white text-[16.336px] leading-[104.065%] hover:text-black-400 font-[400] dark:text-gray-400 dark:hover:text-white "
                  >
                    About
                    <svg
                      className="flex-shrink-0 ms-2 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="m6 9 6 6 6-6" />
                    </svg>
                  </button>

                  <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:start-0 before:w-full before:h-5">
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 text-[16.336px] leading-[104.065%]"
                      to="/about"
                    >
                      Background
                    </Link>

                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 text-[16.336px] leading-[104.065%]"
                      to="/lms"
                    >
                      LMS Modules
                    </Link>
                    <Link
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 text-[16.336px] leading-[104.065%]"
                      to="/timeline"
                    >
                      Timeline
                    </Link>
                  </div>
                </div>
                <Link
                  className="font-[400] text-white hover:text-black-400 sm:py-6 dark:text-gray-400 dark:hover:text-white text-[16.336px] leading-[104.065%]"
                  to="/consortium"
                >
                  The Consortium
                </Link>
                <Link
                  className="font-[400] text-white hover:text-black-400 sm:py-6 dark:text-gray-400 dark:hover:text-white text-[16.336px] leading-[104.065%]"
                  to="/schools"
                >
                  Schools
                </Link>
                <Link
                  className="font-[400] text-white hover:text-black-400 sm:py-6 dark:text-gray-400 dark:hover:text-white text-[16.336px] leading-[104.065%]"
                  to="/activity"
                >
                  Activities
                </Link>
                <Link
                  className="font-[400] text-white hover:text-black-400 sm:py-6 dark:text-gray-400 dark:hover:text-white text-[16.336px] leading-[104.065%]"
                  to="/team"
                >
                  Team
                </Link>
                <Link
                  className="font-[400] text-white hover:text-black-800 sm:py-6 dark:text-gray-400 dark:hover:text-white text-[16.336px] leading-[104.065%]"
                  to="/contact"
                >
                  Contact
                </Link>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Navbar;
