import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";
// images
import HImg from "../Assets/header-img1.png";
import HImg1 from "../Assets/header-img2.jpeg";
import HImg2 from "../Assets/header-img3.jpeg";
import HImg3 from "../Assets/a-img5.jpg";
import img1 from "../Assets/heclogo.png";
import img2 from "../Assets/img1.png";
import img3 from "../Assets/img2.png";
import img4 from "../Assets/img3.png";
import pImg1 from "../Assets/p-img1.jpg";
import pImg2 from "../Assets/p-img2.jpg";
import pImg3 from "../Assets/p-img3.png";
const Home = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        autoplay={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            className="object-center"
            style={{ objectFit: "cover", height: "85vh", width: "100%" }}
            src={HImg3}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="object-center"
            style={{ objectFit: "cover", height: "85vh", width: "100%" }}
            src={HImg}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="object-center"
            style={{ objectFit: "cover", height: "85vh", width: "100%" }}
            src={HImg1}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="object-center"
            style={{ objectFit: "cover", height: "85vh", width: "100%" }}
            src={HImg2}
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="object-center"
            style={{ objectFit: "cover", height: "85vh", width: "100%" }}
            src={HImg}
            alt=""
          />
        </SwiperSlide>
      </Swiper>
      <div className="bg-[#F6F7FD]">
        <div className="flex flex-col md:flex-row align-middle w-[90%] md:w-[80%] mx-auto py-10 gap-10">
          <div className="w-full md:flex-1 flex flex-col items-start justify-center gap-5">
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-bold">Project Brief</h2>
              <div className="overflow-hidden">
                <div
                  className="flex"
                  style={{ animation: "scroll 10s linear infinite" }}
                >
                  <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
                </div>
              </div>
            </div>
            <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              <b>
                “Strengthening of Quality Education at Gilgit Baltistan by Using
                Digital Platforms”{" "}
              </b>
              is one of the projects of KIU led by Dr. Aftab Ahmed Khan as
              Principal Investigator. This project aims to design, develop,
              customize, and integrate an Intelligent Learning Management System
              in the teaching and learning of some selected schools of
              Gilgit-Baltistan. This project falls under Research Grants for
              Local Challenge Fund of Higher Education Commission of Pakistan.
            </p>
          </div>
          <div className="w-full md:w-1/3 flex flex-col items-center">
            <img src={img1} alt="" />
            <p className="text-center text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
              Higher Education Commission This project is funded by HEC under
              Local Challenge Fund.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#F6F7FD]">
        <div className="w-[90%] md:w-[80%] mx-auto py-10 gap-10 flex flex-col items-start justify-center">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-bold">Consortium of Project</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
          <p className="text-justify text-[#303030] text-[16.469px] font-normal leading-[191.696%]">
            The consortium for the project is a diverse group comprising of
            three Universities and two stakeholders from end-users, one each
            from public and private sectors. The lead role shall be assumed by
            KIU, where an energetic team of computer scientists and
            educationists who teach at the Department of computer science and
            Education, respectively is involved. National University of Science
            and Technology (NUST) has opted as a research partner, which is one
            of the leading research institutes. From private sectors
            Universities, the City University of Science and IT Peshawar has
            been chosen for its obvious strength in the computer science and IT
            education, besides having a chain of Peshawar Model Schools and
            Colleges as part of University, where more than 35000 students are
            acquiring quality education.
          </p>
        </div>
        <div className="w-[90%] md:w-[80%] flex-col md:flex-row items-center mx-auto py-10 flex gap-10">
          <img className="w-[380px]" src={img2} alt="" />
          <img className="w-[380px]" src={img3} alt="" />
          <img className="w-[380px]" src={img4} alt="" />
        </div>
      </div>
      <div className="bg-[#F6F7FD] flex flex-col gap-8">
        <div className=" flex flex-col w-max gap-2 px-2 py-5 md:px-16">
          <h2 className="text-2xl font-bold">Implementing Partners</h2>
          <div className="overflow-hidden">
            <div
              className="flex"
              style={{ animation: "scroll 10s linear infinite" }}
            >
              <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
            </div>
          </div>
        </div>
        <div className="patner flex  py-10">
          <div className="flex flex-col md:flex-row justify-between w-[90%] md:w-[80%] mx-auto gap-10">
            <div className="flex flex-col items-center justify-center h-auto gap-2">
              <img
                width={210}
                height={0}
                className="rounded-full"
                src={pImg1}
                alt=""
              />
              <p className="text-white text-[16.469px] font-normal leading-[191.696%]">
                Private Schools
              </p>
            </div>
            <div className="flex flex-col items-center justify-center h-auto gap-2">
              <img
                width={190}
                height={0}
                className="rounded-full"
                src={pImg3}
                alt=""
              />
              <p className="text-white text-[16.469px] font-normal leading-[191.696%]">
                Aga Khan Education Service, Pakistan
              </p>
            </div>
            <div className="flex flex-col items-center justify-center h-auto gap-2">
              <img
                width={190}
                height={0}
                className="rounded-full"
                src={pImg2}
                alt=""
              />
              <p className="text-white text-[16.469px] font-normal leading-[191.696%]">
                Government Education Department
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
