import React from "react";

const Timeline = () => {
  return (
    <>
      <div className="bg-[#F6F7FD] py-10 flex justify-center">
        <div className="w-full md:w-1/2">
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Detail work plan/MoUs signatures with sectoral colloborators and
                tasks distribution
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Detail Literature Review
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Baaseline Survey
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Project and Schools Website Development
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Requirements Analysis
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Software Development and Data Layer Design
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Software Development: Presentation Layer Development
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Software Development: Application Layer Development
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Training for the staff, teachers, and students
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Assessment Survey from users
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Report Writing/Submission of papers to journals
              </p>
            </div>
          </div>
          <div className="flex gap-x-3 w-full">
            <div className="w-40 text-end">
              <span className="text-lg text-gray-500 dark:text-gray-400">
                Progress 100%
              </span>
            </div>

            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
              <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                <div className="w-2 h-2 rounded-full bg-blue-400 dark:bg-gray-600"></div>
              </div>
            </div>
            <div className="grow pt-0.5 pb-8">
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Project Results dissemination Workshop/Consultations/Shows/
                Meetings
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;
