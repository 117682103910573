import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../layouts/home";
import Background from "../layouts/background";
import LMS from "../layouts/lms";
import Timeline from "../layouts/timeline";
import Consortium from "../layouts/consortium";
import Schools from "../layouts/schools";
import Activity from "../layouts/activity";
import Team from "../layouts/team";
import Contact from "../layouts/contact";

const Navigation = () => {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Background />} /> 
        <Route path="/lms" element={<LMS />} />
        <Route path="/timeline" element={<Timeline />} />
        <Route path="/consortium" element={<Consortium />} />
        <Route path="/schools" element={<Schools />} />
        <Route path="/activity" element={<Activity />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
  );
};

export default Navigation;
