import React from "react";
import Limg1 from "../Assets/team/l-img1.png";
import Limg2 from "../Assets/team/l-img2.jpg";
import Limg3 from "../Assets/team/l-img3.jpg";
import Limg4 from "../Assets/team/l-img4.jpg";
import Limg5 from "../Assets/team/l-img5.png";
import Limg6 from "../Assets/team/l-img6.jpg";
import Limg7 from "../Assets/team/l-img7.png";
import Dimg1 from "../Assets/team/d-img1.jpg";
import Dimg2 from "../Assets/team/d-img2.jpeg";
import Dimg3 from "../Assets/team/d-img3.jpg";
import Dimg4 from "../Assets/team/d-img4.jpg";
import Dimg5 from "../Assets/team/d-img5.jpg";
import Dimg6 from "../Assets/team/d-img6.jpg";
import Dimg7 from "../Assets/team/d-img7.jpeg";
import Dimg8 from "../Assets/team/d-img8.JPG";
import Dimg9 from "../Assets/team/d-img9.jpeg";
import Dimg10 from "../Assets/team/d-img10.jpg";
import Dimg11 from "../Assets/team/d-img11.jpg";
import Dimg12 from "../Assets/team/d-img12.jpg";
import Dimg13 from "../Assets/team/d-img13.jpg";
import Iimg1 from "../Assets/team/i-img1.jpeg";
import Iimg2 from "../Assets/team/i-img2.jpeg";
import Iimg3 from "../Assets/team/i-img3.webp";
import Iimg4 from "../Assets/team/i-img4.jpg";
import Iimg5 from "../Assets/team/i-img5.jpg";
import Iimg6 from "../Assets/team/i-img6.jpeg";
import Iimg7 from "../Assets/team/i-img7.png";
import Iimg8 from "../Assets/team/i-img8.jpg";
import Iimg9 from "../Assets/team/i-img9.jpg";
import Iimg10 from "../Assets/team/i-img10.webp";

const Leadership = [
  {
    img: Limg1,
    name: "Dr. Aftab Ahmed Khan",
    position: "Associate Professor, KIU (Principal Investigator)",
  },
  {
    img: Limg2,
    name: "Prof. Dr. Attah Ullah Shah ",
    // position: "Ensure proper project management ",
    position: "Vice Chancellor, KIU",
  },
  {
    img: Limg3,
    name: "Dr. Asif khan ",
    position: "Associate Professor, KIU",
    // position:
    //   "Will work on the development of training modules and give a specialized education aspect of ",
  },
  {
    img: Limg4,
    name: "Mr. Imran Ali",
    position: "Lecturer, KIU ",
    // position:
    //   "Will work and assist PI in documentation and supervision of the project.",
  },
  {
    img: Limg5,
    name: "Dr. Muhammad Arshad",
    position: "Associate Professor, CUIT",
    // position:
    //   "Will work and assist PI in project planning and development details.",
  },
  {
    img: Limg6,
    name: "Mr. Khalid Amin",
    position: "Director IT, KIU",
    // position:
    //   "Will ensure proper implementation of development and training modules on software.",
  },
  {
    img: Limg7,
    name: "Dr. Wasi Haider Butt ",
    // position:
    //   "Will work and assist PI in research work and implementation of AI modules. ",
    position: "Associate Professor, NUST",
  },
];

const developmentTeam = [
  {
    img: Dimg2,
    name: "Mr. Salar Hussain",
    position: "Full Stack Developer",
  },
  {
    img: Dimg1,
    name: "Mr. Riaz Ali",
    position: "Research Assistant",
  },
  {
    img: Dimg13,
    name: "Mr. Affaq",
    position: "Full Stack Developer",
  },
  {
    img: Dimg3,
    name: "Mr. Nouman Ejaz",
    position: "Student Assistant. KIU",
  },
  {
    img: Dimg8,
    name: "Mr. ShahNawaz",
    position: "Backend Developer",
  },
  {
    img: Dimg9,
    name: "Mr.Ahmed Saleem",
    position: "Frontend developer",
  },
  {
    img: Dimg11,
    name: "Mr. Zeeshan Ali",
    position: "Admin Officer",
  },
  {
    img: Dimg10,
    name: "Mr. Kashif Aziz",
    position: "Data Analyst",
  },
  {
    img: Dimg12,
    name: "Mr. Kalim Hussain",
    position: "UI/UX designer",
  },

  {
    img: Dimg4,
    name: "Mr. Tahir Malik",
    position: "Student Assistant. KIU",
  },
  {
    img: Dimg5,
    name: "Ms . Aliya Ahmed",
    position: "Student Assistant. KIU",
  },
  {
    img: Dimg6,
    name: "Ms. Fatima Batool ",
    position: "Student Assistant. KIU",
  },
  {
    img: Dimg7,
    name: "Mr. Shaheen Khan",
    position: "Accountant",
  },
];

const Internee = [
  {
    img: Iimg1,
    name: "Mr. Muzaffar Faqir",
    position: "Internee",
  },
  {
    img: Iimg2,
    name: "Mr. Naeem Ullah Khan",
    position: "Internee",
  },
  {
    img: Iimg3,
    name: "Mr. Usama Shuaib ",
    position: "Internee",
  },
  {
    img: Iimg4,
    name: "Ms. Sidra ",
    position: "Internee",
  },
  {
    img: Iimg5,
    name: "Mr. Shuaib Malik ",
    position: "Internee",
  },
  {
    img: Iimg6,
    name: "Ms. Sabira Shabbir ",
    position: "Internee",
  },
  {
    img: Iimg7,
    name: "Ms. Shazma Baig ",
    position: "Internee",
  },
  {
    img: Iimg8,
    name: "Mr. Amir malik Raki ",
    position: "Internee",
  },
  {
    img: Iimg9,
    name: "Ms. Manahil Aamir ",
    position: "Internee",
  },
  {
    img: Iimg10,
    name: "Mr. Aftab Ahmad Khan",
    position: "Internee",
  },
];
const Team = () => {
  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="w-full mx-auto text-center flex justify-center mb-6">
          <div className="flex flex-col gap-2 w-max mx-auto mb-6">
            <h2 className="text-4xl font-bold text-wrap">Leadership</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-8 md:gap-12">
          {Leadership.map((item) => (
            <div className="text-center">
              <img
                className="rounded-xl w-48 h-48 sm:w-48 sm:h-48 lg:w-60 lg:h-60 mx-auto object-cover"
                src={item.img}
                alt=""
              />
              <div className="mt-2 sm:mt-4">
                <h3 className="text-sm font-medium text-gray-800 sm:text-base lg:text-lg dark:text-gray-200">
                  {item.name}
                </h3>
                <p className="text-xs text-gray-600 sm:text-sm lg:text-base dark:text-gray-400">
                  {item.position}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-6">
        <div className="w-full mx-auto text-center flex justify-center mb-6">
          <div className="flex flex-col gap-2 w-max mx-auto mb-6">
            <h2 className="text-4xl font-bold text-wrap">Development Team</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-8 md:gap-12">
          {developmentTeam.map((item) => (
            <div className="text-center">
              <img
                className="rounded-xl w-48 h-48 sm:w-48 sm:h-48 lg:w-60 lg:h-60 mx-auto object-cover"
                src={item.img}
                alt=""
              />
              <div className="mt-2 sm:mt-4">
                <h3 className="text-sm font-medium text-gray-800 sm:text-base lg:text-lg dark:text-gray-200">
                  {item.name}
                </h3>
                <p className="text-xs text-gray-600 sm:text-sm lg:text-base dark:text-gray-400">
                  {item.position}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-6">
        <div className="w-full mx-auto text-center flex justify-center mb-6">
          <div className="flex flex-col gap-2 w-max mx-auto mb-6">
            <h2 className="text-4xl font-bold text-wrap">Internee</h2>
            <div className="overflow-hidden">
              <div
                className="flex"
                style={{ animation: "scroll 10s linear infinite" }}
              >
                <p className="text-black h-1 bg-[#0000ff] w-full">***</p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-8 md:gap-12">
          {Internee.map((item) => (
            <div className="text-center">
              <img
                className="rounded-xl w-48 h-48 sm:w-48 sm:h-48 lg:w-60 lg:h-60 mx-auto object-cover"
                src={item.img}
                alt=""
              />

              <div className="mt-2 sm:mt-4">
                <h3 className="text-sm font-medium text-gray-800 sm:text-base lg:text-lg dark:text-gray-200">
                  {item.name}
                </h3>

                <p className="text-xs text-gray-600 sm:text-sm lg:text-base dark:text-gray-400">
                  {item.position}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Team;
